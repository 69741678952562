<template>
  <div class="LogoSlogan bg-primary-darkest px-12 py-20">
    <div class="flex justify-center flex-col items-center text-white mb-10">
      <JoszakiLogo sizing="slogan" class="w-full max-w-[600px]" />
      <p class="mt-4 text-center font-bold text-4xl">
        {{ $t("branding.slogan") }}
      </p>
    </div>
  </div>
</template>

<script>
import JoszakiLogo from "../img/JoszakiLogo.vue";
export default {
  components: { JoszakiLogo },
};
</script>
