<template>
  <a
    class="inline-flex items-center justify-center text-white bg-dark-blue border-white hover:bg-white hover:text-dark-blue hover:border-dark-blue border-solid border rounded-full !p-3 mr-3"
    :href="url"
    target="_blank"
  >
    <IconComponent
      :pack="iconPack"
      :icon="logo"
      aria-hidden="true"
      class="w-4 h-4"
    />
  </a>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    logo: {
      type: String,
      required: true,
    },
    iconPack: {
      type: String,
      default: "fab",
    },
  },
};
</script>
