<template>
  <div class="bg-primary-darker py-14 hidden md:flex justify-center">
    <div class="container flex flex-col">
      <div class="flex flex-col justify-center">
        <div class="flex align-middle justify-center pb-3 text-5xl">
          <IconComponent class="text-white" icon="map-marker-alt" />
        </div>
        <div class="flex align-middle justify-center text-white text-2xl">
          {{ $t("personList.cities") }}
        </div>
      </div>
      <div class="flex flex-row gap-10">
        <div
          v-for="(countyList, index) in countyChunks"
          :key="index"
          class="flex-1"
        >
          <div v-for="county in countyList" :key="county.countySeoName">
            <CityLink :county="county" :profession="profession" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CityLink from "./CityLink.vue";

const SPLIT_SIZE = 5;

export default {
  components: {
    CityLink,
  },
  computed: {
    counties() {
      return this.$store?.getters["data/counties"];
    },
    profession() {
      return this.$store?.getters["listPage/profession"];
    },
    countyChunks() {
      const result = [];

      for (let i = 0; i < this.counties.length; i += SPLIT_SIZE) {
        result.push(this.counties.slice(i, i + SPLIT_SIZE));
      }

      return result;
    },
  },
};
</script>
