<template>
  <div>
    <div class="mb-3">
      <slot name="trigger" :toggle="toggle"></slot>
    </div>
    <div
      class="max-h-0 md:max-h-full overflow-hidden transition-[max-height] duration-300 ease-in-out"
      :class="{
        '!max-h-full': isOpen,
      }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { ref } from "@nuxtjs/composition-api";
export default {
  setup() {
    const isOpen = ref(false);

    function toggle() {
      isOpen.value = !isOpen.value;
    }

    return {
      isOpen,
      toggle,
    };
  },
};
</script>
