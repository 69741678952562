<template>
  <div class="bg-primary-darker py-14 hidden md:flex flex-col items-center">
    <div>
      <div class="flex align-middle justify-center pb-3 text-5xl">
        <IconComponent class="text-white" icon="wrench" />
      </div>
      <div class="flex align-middle justify-center text-white text-2xl">
        {{ $t("personList.professions") }}
      </div>
    </div>
    <div class="flex flex-row justify-center gap-10">
      <div
        v-if="!$branding.isSuperprofikCz && !$branding.isBerufExperten"
        class="w-96"
      >
        <section>
          <h1 class="text-2xl text-primary-lightest pb-8 text-center">
            {{ $t("professionLinks.professionGroups") }}
          </h1>
        </section>
        <div class="grid grid-cols-2">
          <ul
            v-for="(array, index) in groups"
            :key="index"
            :class="{
              'text-center': groups.length === 1,
              'text-end': groups.length === 2 && index === 1,
            }"
          >
            <li
              v-for="professionGroup in array"
              :key="`plpg${professionGroup.id}`"
              class="mb-2"
            >
              <NuxtLink
                class="text-white hover:text-primary-lighter mr-auto"
                :to="
                  localePath({
                    name: 'professionsPage',
                    params: { professionGroup: professionGroup.seoName },
                  })
                "
              >
                {{ professionGroup.name }}
              </NuxtLink>
            </li>
          </ul>
        </div>
      </div>
      <div class="w-96">
        <section>
          <h1 class="text-2xl text-primary-lightest pb-8 text-center">
            {{ $t("professionLinks.popularProfessions") }}
          </h1>
        </section>
        <div
          class="grid"
          :class="{
            'grid-cols-2': professionsArrays.length === 2,
          }"
        >
          <ul
            v-for="(professionArray, index) in professionsArrays"
            :key="index"
            :class="{
              'text-center': professionsArrays.length === 1,
              'text-end': professionsArrays.length === 2 && index === 1,
            }"
          >
            <li
              v-for="profession in professionArray"
              :key="`plp${profession.id}`"
              class="mb-2"
            >
              <NuxtLink
                class="text-white hover:text-primary-lighter mr-auto first-letter:capitalize whitespace-nowrap"
                :to="
                  localePath({
                    name: 'professionPage',
                    params: { profession: profession.seoName },
                  })
                "
              >
                {{ profession.name }}
              </NuxtLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    city() {
      return this.$route.params.city;
    },
    professionGroups() {
      return this.$store.getters["data/professionGroups"];
    },
    popularProfessions() {
      return this.$store.getters["data/popularProfessions"];
    },
    groupsHalfSize() {
      return Math.ceil(this.professionGroups.length / 2) || 1;
    },
    professionsArrays() {
      if (this.popularProfessions.length <= 7) {
        return [this.popularProfessions];
      }

      return [
        this.popularProfessions.slice(0, 7),
        this.popularProfessions.slice(7),
      ];
    },
    groups() {
      return [
        this.professionGroups.slice(0, this.groupsHalfSize),
        this.professionGroups.slice(
          this.groupsHalfSize,
          this.professionGroups.length
        ),
      ];
    },
  },
};
</script>
